import revive_payload_client_uc8tYme3nN from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/nuxt@3.14.1592_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@20.16.11_encoding@0.1.1_rpwwkqm37tpukuth6nr3bd5yni/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_jJWzxK0gGm from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/nuxt@3.14.1592_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@20.16.11_encoding@0.1.1_rpwwkqm37tpukuth6nr3bd5yni/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_2LFD7blNYP from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/nuxt@3.14.1592_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@20.16.11_encoding@0.1.1_rpwwkqm37tpukuth6nr3bd5yni/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_4QvWbsSp0L from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/nuxt@3.14.1592_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@20.16.11_encoding@0.1.1_rpwwkqm37tpukuth6nr3bd5yni/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_vY3WMU6fBx from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/nuxt@3.14.1592_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@20.16.11_encoding@0.1.1_rpwwkqm37tpukuth6nr3bd5yni/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_VW8AKfqOfM from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/nuxt@3.14.1592_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@20.16.11_encoding@0.1.1_rpwwkqm37tpukuth6nr3bd5yni/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_BbXjLnDi4u from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/nuxt@3.14.1592_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@20.16.11_encoding@0.1.1_rpwwkqm37tpukuth6nr3bd5yni/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_8Mwrgs7QtV from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/nuxt@3.14.1592_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@20.16.11_encoding@0.1.1_rpwwkqm37tpukuth6nr3bd5yni/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_1xkZ9RgEeX from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.4_typescript@5.7.2_vue@3.5.13_typescript@5.7.2__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/.nuxt/components.plugin.mjs";
import prefetch_client_xTDPhmUbMb from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/nuxt@3.14.1592_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@20.16.11_encoding@0.1.1_rpwwkqm37tpukuth6nr3bd5yni/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/.nuxt/pwa-icons-plugin.ts";
import pwa_client_8a1w2aejMY from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/@vite-pwa+nuxt@0.10.5_@vite-pwa+assets-generator@0.0.11_magicast@0.3.5_rollup@4.24.4_vite@5.4_dui5eze2pxsrnmmzwjitv6mrty/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import unocss_MzCDxu9LMj from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/.nuxt/unocss.mjs";
import pendo_hkesixFq4o from "/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-pendo/src/runtime/pendo.ts";
import datadog_JKmUALlR6H from "/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-datadog/src/runtime/datadog.ts";
import plugin_526V8oWKGJ from "/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth0/src/runtime/plugin.ts";
import formkitPlugin_pZqjah0RUG from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/.nuxt/formkitPlugin.mjs";
import plugin_PXQeuxRKoc from "/builds/rialtic/qa/argocd-releases/console-ui/packages/ui/src/runtime/plugin.ts";
import plugin_84GIP90CO6 from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.0_@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.4_typesc_ntngozffnqklza7apbvzmoi7qu/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
export default [
  revive_payload_client_uc8tYme3nN,
  unhead_jJWzxK0gGm,
  router_2LFD7blNYP,
  payload_client_4QvWbsSp0L,
  navigation_repaint_client_vY3WMU6fBx,
  check_outdated_build_client_VW8AKfqOfM,
  view_transitions_client_BbXjLnDi4u,
  chunk_reload_client_8Mwrgs7QtV,
  plugin_vue3_1xkZ9RgEeX,
  components_plugin_KR1HBZs4kY,
  prefetch_client_xTDPhmUbMb,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_8a1w2aejMY,
  unocss_MzCDxu9LMj,
  pendo_hkesixFq4o,
  datadog_JKmUALlR6H,
  plugin_526V8oWKGJ,
  formkitPlugin_pZqjah0RUG,
  plugin_PXQeuxRKoc,
  plugin_84GIP90CO6
]